<template>
  <FinalField :name="name" :validate="validate" ref="field">
    <template v-slot="props">
      <div class="form-col">
        <div class="flex justify-between items-center">
          <label :for="name">{{ label }}</label>
          <div class="space-x-2 text-right">
            <button @click="selectAll" v-show="showSelectAll(props.value)" type="button" class="btn-clear">select all</button>
            <button @click="clearAll" v-show="props.value && props.value.length" type="button" class="btn-clear">clear all</button>
          </div>
        </div>

        <Multiselect
          :data-test="name"
          :placeholder="placeholder"
          :value="getValue(props.value)"
          :options="computedOptions"
          :multiple="true"
          :hide-selected="hideSelected"
          :disabled="disabled"
          :close-on-select="closeOnSelect"
          :custom-label="customLabel"
          trackBy="key"
          label="value"
          @input="handleInput"
        >
          <template v-slot:selection="{ values, isOpen }">
            <span class="multiselect__single" v-if="values.length && !isOpen">
              {{values.length}} option{{ values.length > 1 ? 's' : '' }} selected
            </span>
          </template>
          <template v-if="hideTags" v-slot:tag>
            <span></span>
          </template>
        </Multiselect>
        <span class="form-hint">{{ hint }}</span>
        <span class="form-error" v-if="props.meta.error && props.meta.touched">{{ props.meta.error }}</span>
        <div class="chips-container">
            <slot name="tags" :value="props.value" :onRemove="removeSelection">
                <Tag v-for="option in getValue(props.value)"
                     :key="option.key"
                     :text="option.value"
                     @click="removeSelection(option.key)"
                     deletable
                >
                </Tag>
            </slot>
        </div>
      </div>
    </template>
  </FinalField>
</template>

<script>
  import {FinalField} from 'vue-final-form';
  import Multiselect from 'vue-multiselect';
  import Tag from "@/components/ui/Tag";
  import {isEqual} from "lodash-es";

  export default {
    name: "DropdownMultiselectInput",
    components: {
      Tag,
      Multiselect,
      FinalField,
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
      },
      hint: {
        type: String,
        required: false,
      },
      hideTags: {
        type: Boolean,
        default: false,
      },
      hideSelected: {
        type: Boolean,
        default: true,
      },
      closeOnSelect: {
        type: Boolean,
        default: false,
      },
      validate: {
        type: [Function, Array],
        required: false,
      },
      disabled: {
        type: Boolean,
        default: false
      },
      options: {
        type: Array,
        required: true
      },
      beforeChange: {
        type: Function,
        default: function (value, change) {
          change(value);
        }
      },
      getValue: {
        type: Function,
        default: function (value) {
          if (!value || typeof value === 'object') {
            return value;
          } else {
            return this.options.find(option => option.key === value);
          }
        }
      },
      allOption: {
        type: Boolean,
        default: false
      },
      customLabel: {
          type: Function,
          default: (option) => (option.value),
      },
    },
    data() {
      return {
        allObj: {
          key: 'all',
          value: 'all'
        }
      };
    },
    computed: {
      change: function () {
        const {fieldState: {change}} = this.$refs.field;
        return change;
      },
      computedOptions: function () {
        let opts = [...this.options];

        if (this.allOption) {
          opts = [this.allObj, ...opts];
        }

        return opts;
      }
    },
    methods: {
      handleInput(value) {
        const all = value.find(o => o.key === 'all');
        this.beforeChange(all ? [this.allObj] : value, this.change);
      },
      removeSelection(key) {
        const {fieldState: {value}} = this.$refs.field;
        const newVal = value.filter(i => i.key !== key);
        this.beforeChange(newVal, this.change);
      },
      selectAll() {
        this.beforeChange(this.allOption ? [this.allObj] : this.options, this.change);
      },
      clearAll() {
        this.beforeChange([], this.change);
      },
      showSelectAll(value) {
        return !value || ((value.length < this.options.length && !this.allOption) || (this.allOption && !isEqual(value, [this.allObj])));
      }
    }
  }
</script>

<style scoped>
  .btn-clear {
    @apply text-sm text-blue-500;
  }

  .multiselect__tag-icon {
    border-radius: 0;
  }

  .multiselect__tag-icon:hover {
    @apply bg-blue-700;
  }

  .chips-container {
    overflow: auto;
    max-height: 15rem;
  }
</style>
